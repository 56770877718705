pre {
	border: 1px solid lightgray;
	padding: 1rem;
}

res {
    color: darkred;
    font-weight: bold;
}

obj {
    color: green;
    font-weight: bold;
}

func {
    font-weight: bold;
}

prim {
    color: magenta;
    font-weight: bold;
}

rem {
	color: #6A9955;
	font-style: italic;
}
