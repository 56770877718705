.Header {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Header .left {
    display: inline-block;
}

.Header .right {
    float: right;
}

.Header p {
    display: inline;
    font-weight: bold;
}

.Header a {
    color: inherit;
    text-decoration: none;
    font-style: italic;
    margin-left: 2rem;
}

.Header a:hover {
    font-weight: bold;
}
